import React, { useRef, useState } from 'react';
import {
    Nav,
    Navbar,
    NavDropdown,
    Container,
    Row, Col, Button, Card,
    CardBody, Form, Spinner
} from "react-bootstrap";
import logo from "../assets/images/logo.png";
import comp from "../assets/images/comp.png";
import whatsapp from "../assets/images/whatsapp.png";
import webdesign from "../assets/images/webdesign.png";
import mobdev from "../assets/images/mobdev.png";
import uidev from "../assets/images/uidev.png";
import softwaredev from "../assets/images/softwaredev.png";
import linkedin from "../assets/images/linkedin.png";
import java from "../assets/images/java.png";
import angular from "../assets/images/angular.png";
import react from "../assets/images/react.png";
import oracle from "../assets/images/oracle.png";
import nutralongas from "../assets/images/nutralongas.png";
import android from "../assets/images/android.png";
import electron from "../assets/images/electron.png";
import csharp from "../assets/images/csharp.png";
import python from "../assets/images/python.png";
import flutter from "../assets/images/flutter.png";
import mysql from "../assets/images/mysql.png";
import golang from "../assets/images/golang.png";
import swift from "../assets/images/swift.png";
import html from "../assets/images/html.png";
import bootstrap from "../assets/images/bootstrap.png";
import reactjs from "../assets/images/reactjs.png";
import mission from "../assets/images/mission.png";
import vision from "../assets/images/vision.png";
import frame7 from "../assets/images/frame7.png";
import frame3 from "../assets/images/frame3.png";
import frame5 from "../assets/images/frame5.png";
import frame1 from "../assets/images/frame1.png";
import frame6 from "../assets/images/frame6.png";
import frame2 from "../assets/images/frame2.png";
import frame4 from "../assets/images/frame4.png";
import ashwin from "../assets/images/ashwin.png";
import shrikant from "../assets/images/shrikant.png";
import rahul from "../assets/images/rahul.png";
import sneha from "../assets/images/sneha.png";
import kiran from "../assets/images/kiran.png";
import rohan from "../assets/images/rohan.png";
import linkedinimg from "../assets/images/linkedinimg.png";
import location from "../assets/images/location.png";
import mail from "../assets/images/mail.png";
import phone from "../assets/images/phone.png";
// import config from "config";
import { toast, ToastContainer } from 'react-toastify';
export default function Home() {
    const formRef = useRef(null);
    const [errors, setErrors] = useState({});
    const contactRef = useRef(null);
    const aboutRef = useRef(null);
    const serviceRef = useRef(null);
    const productRef = useRef(null);
    const technologyRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        mobileno: '',
        subject: '', // Add subject field here
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const error = validateField(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: error
        });
    };
    const validateField = (name, value) => {
        let error;
        switch (name) {
            case 'fullname':
                if (!value) {
                    error = 'Name is required';
                } else if (!/^[a-zA-Z ]+$/.test(value)) {
                    error = 'Name can only contain letters and spaces';
                }
                break;
            case 'mobileno':
                if (!value) {
                    error = 'Mobile number is required';
                } else if (!/^[0-9]{10}$/.test(value)) {
                    error = 'Mobile number must be 10 digits';
                }
                break;
            case 'email':
                if (!value) {
                    error = 'Email is required';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = 'Email is invalid';
                }
                break;

            default:
                break;
        }
        return error;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const {
            fullname,
            mobileno,
            email,
            subject,
            message,
        } = e.target.elements;
        let details = {
            fullname: fullname.value,
            mobileno: mobileno.value,
            email: email.value,
            subject: subject.value,
            message: message.value,
        };
        // Check for errors before submitting
        const validationErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) {
                validationErrors[key] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        } try {
            let response = await fetch(`/contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json", // Corrected header
                },
                body: JSON.stringify(details),
            });

            let result = await response.json();
            console.log("result.status", result.status);
            // formRef.resetForm();
            setFormData({
                fullname: '',
                mobileno: '',
                email: '',
                subject: '',
                message: '',
            });
            formRef.current.reset();

            toast.success('Form Submitted Successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.error("Error:", error);
            toast.error('Form Submission Failed', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        finally {
            setLoading(false);
        }
    };
    const resetFormData = () => {
        setFormData({
            fullname: '',
            mobileno: '',
            email: '',
            subject: '',
            message: '',
        });
        formRef.current.reset();

        setErrors({});
    }
    return (
        <>
            <div style={{ overflowX: "hidden" }}>
                <div className='banner'>
                    <Navbar collapseOnSelect expand="lg" className="navstyle">
                        <Container>
                            <Navbar.Brand href="#home">
                                <div className=''>
                                    <img src={logo} className='logostyle' />
                                </div>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    {/* <Nav.Link href="#features">Features</Nav.Link>
                            <Nav.Link href="#pricing">Pricing</Nav.Link>
                            <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
                                </Nav>
                                <Nav>
                                    <Nav.Link className='text-white' onClick={() => scrollToSection(aboutRef)}>About Us</Nav.Link>
                                    <Nav.Link eventKey={2} className='text-white' onClick={() => scrollToSection(serviceRef)}>
                                        Service
                                    </Nav.Link>
                                    <Nav.Link eventKey={3} className='text-white' onClick={() => scrollToSection(productRef)}>
                                        Products
                                    </Nav.Link>
                                    <Nav.Link eventKey={4} className='text-white' onClick={() => scrollToSection(technologyRef)}>
                                        Technology
                                    </Nav.Link>
                                    <Nav.Link eventKey={5} className='text-white' onClick={() => scrollToSection(contactRef)}>
                                        Contact Us
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <Container>
                        <div>
                            <Row>
                                <Col lg="6" md="6" sm="12" xs="12" className='mt-5'>
                                    <p className='bannerhead'>
                                        {/* We focus on building ‘scalable’ enterprise and consumer-facing applications */}
                                        We Focus on Building
                                        Scalable and Enterprise applications.

                                    </p>
                                    <div className=''>
                                        <Button className='bannerbtn' type="button" onClick={() => scrollToSection(aboutRef)}>Who We Are</Button>
                                    </div>
                                    <div className='d-flex'>
                                        <p className='text-white social me-2'>Follow Us</p>
                                        <img src={whatsapp} className='mx-3 icostyle' />
                                        {/* <img src={twitter} className='me-2 icostyle' /> */}
                                        <img src={linkedin} className='icostyle' />
                                    </div>
                                </Col>
                                <Col lg="6" md="6" sm="12" xs="12" >
                                    <img src={comp} className='compimg' />
                                </Col>
                            </Row>
                        </div>

                    </Container>

                </div>
                <div className='services'>
                    <Row className='justify-content-center'>
                        <Col lg="8" className='px-3'>
                            <div id="service" ref={serviceRef}>
                            <p className='head'>Our Services</p>
                            <p className='text-style'>WE ADD VALUE AND DELIVER RESULTS</p>
                            <Row>
                                <Col lg="3" md="3" sm="6" xs="6" className="mb-2">
                                    <Card className="text-center cardstyle">
                                        <Card.Body>
                                                <img src={webdesign} className='mb-2 serviceimg' />
                                            <Card.Title className='titlestyle'>Web Design & Development</Card.Title>
                                            <Card.Text className='cardtext'>
                                                A strategic and innovative
                                                website design company in Pune
                                                that delivers sustainable designs on which businesses can rely on.
                                            </Card.Text>
                                            <div class="hover-content">
                                                <a href="#" class="know-more">Know More &gt;</a>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className="mb-2">
                                    <Card className="text-center cardstyle">
                                        <Card.Body>
                                                <img src={mobdev} className='mb-2 serviceimg' />
                                            <Card.Title className='titlestyle'>Mobile App Development</Card.Title>
                                            <Card.Text className='cardtext'>
                                                    As the leading hybrid app development company, we understand the type of advanced hybrid app frameworks.
                                            </Card.Text>
                                            <div class="hover-content">
                                                <a href="#" class="know-more">Know More &gt;</a>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className="mb-2">
                                    <Card className="text-center cardstyle">
                                        <Card.Body>
                                                <img src={uidev} className='mb-2 serviceimg' />
                                            <Card.Title className='titlestyle'>UI UX Developement</Card.Title>
                                            <Card.Text className='cardtext'>
                                                    UI & UX are important facts of the custom software development process,working closely with users to create interfaces.
                                            </Card.Text>
                                            <div class="hover-content">
                                                <a href="#" class="know-more">Know More &gt;</a>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className="mb-2">
                                    <Card className="text-center cardstyle">
                                        <Card.Body>
                                                <img src={softwaredev} className='mb-2 serviceimg' />
                                            <Card.Title className='titlestyle'>Software Developement</Card.Title>
                                            <Card.Text className='cardtext'>
                                                    As the leading hybrid app development company, we understand the type of advanced hybrid app frameworks.
                                            </Card.Text>
                                            <div class="hover-content">
                                                <a href="#" class="know-more">Know More &gt;</a>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            </div>
                            <div id="technology" ref={technologyRef}>
                            <p className='head'>Technology Stack</p>
                            <Row>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={java} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Java.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={angular} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Angular
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={react} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                React-Native
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={oracle} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Oracle
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={nutralongas} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Neutralinojs
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={android} className=' languageico' />
                                            <Card.Text className='cardtext'>
                                                Android
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={electron} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Electron
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={python} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Python
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={flutter} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Flutter
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={mysql} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                My SQL
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={golang} className=" languageico" />
                                            <Card.Text className='cardtext'>
                                                Golang
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={csharp} className=' languageico' />
                                            <Card.Text className='cardtext'>
                                                C++
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={swift} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                Swift IOS
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={reactjs} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                React
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={bootstrap} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                BootStrap
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="6" xs="6">
                                    <Card className="text-center stackcard">
                                        <Card.Body className='contentstyle'>
                                            <img src={html} className='languageico' />
                                            <Card.Text className='cardtext'>
                                                HTML5
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            </div>
                        </Col>
                    </Row>
                    <div id="about" ref={aboutRef}>
                    <Row className='justify-content-center'>
                            <Col lg="8" className='px-3'>
                            <Card style={{ backgroundColor: "#0A4067" }}>
                                <CardBody>
                                    <p className='head pt-0 text-center'>Who We Are</p>
                                    <p className='txtstyle'>
                                        "Opethic" is a User Experience & quality-focused company that enables small scale and enterprises to automate the processes using the cutting-edge technolgy to digitize the business.
                                    </p>
                                    <p className='txtstyle'>
                                        Team of proffessionals who are keen to automate the small scale businesses using the cutting edge technology. Opethic is application development company with a focus on building "scalable" enterprise and consumer-facing applications which allows them to achieve the expected growth in the business. Our services includes end to end product development - UI Designing, UX development, scalable Web and Mobile Applications using the latest technologies. We specialize in automating the business processes for our customers that help them enhance productivity and aligns with their working style. We have a vast expereince in supporting the customers from Manufacturing sector to the last milestone of Retailers and covers the MSME Sector.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-4'>
                            <Col lg="8" className='px-3'>
                            <Row className='mb-4'>
                                <Col lg="2" md="2" sm="4" xs="4">
                                    <Card className='vmcardimg'>
                                        <img src={mission} />
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="8" xs="8">
                                    <Card className='vmcard'>
                                        <p className='head pt-0'>Vission</p>
                                        <p className='txtstyle'>
                                            Enable the businesses to achieve there growth using the innovative technology which ease their day to day operations.
                                        </p>
                                    </Card>
                                </Col>
                                <Col lg="2" md="2" sm="4" xs="4">
                                    <Card className='vmcardimg'>
                                        <img src={vision} />
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="8" xs="8">
                                    <Card className='vmcard'>
                                        <p className='head pt-0'>Mission</p>
                                        <p className='txtstyle'>
                                            Use of latest technologies to automate the business processes supporting the MSME Industries from various sectors like Manufacturing, Education, Travel and Tourisum, Banking and Finance Sector, Retail, FMCG.
                                        </p>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        </Row>
                    </div>
                </div>
                <div className='products py-5' id="product" ref={productRef}>
                    <Row className='justify-content-center'>
                        <Col lg="8" className='px-3'>
                            <p className='head mb-5'>Our Products</p>
                            <Row>
                                <Col lg="3" md="3" sm="6" xs="6" className='mb-2'>
                                    <Card className="text-center">
                                        <Card.Body className='p-0' >
                                            <div className='image-container'>
                                                <img variant="top" src={frame7} className='proimg' />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-muted cardfooter">Ethics Core</Card.Footer>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className='mb-2'>
                                    <Card className="text-center">
                                        <Card.Body className='p-0' >
                                            <div className='image-container'>
                                                <img variant="top" src={frame3} className='proimg' />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-muted cardfooter">Order & Inventory</Card.Footer>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className='mb-2'>
                                    <Card className="text-center">
                                        <Card.Body className='p-0' >
                                            <div className='image-container'>
                                                <img variant="top" src={frame5} className='proimg' />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-muted cardfooter">Restaurant</Card.Footer>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className='mb-2'>
                                    <Card className="text-center">
                                        <Card.Body className='p-0' >
                                            <div className='image-container'>
                                                <img variant="top" src={frame6} className='proimg' />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-muted cardfooter">School Management</Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col lg="3" md="3" sm="6" xs="6" className='mb-2'>
                                    <Card className="text-center">
                                        <Card.Body className='p-0' >
                                            <div className='image-container'>
                                                <img variant="top" src={frame4} className='proimg' />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-muted cardfooter">HRMS</Card.Footer>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className='mb-2'>
                                    <Card className="text-center">
                                        <Card.Body className='p-0' >
                                            <div className='image-container'>
                                                <img variant="top" src={frame2} className='proimg' />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-muted cardfooter">Manufacturing</Card.Footer>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className='mb-2'>
                                    <Card className="text-center">
                                        <Card.Body className='p-0' >
                                            <div className='image-container'>
                                                <img variant="top" src={frame1} className='proimg' />
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="text-muted cardfooter">Business</Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='team'>
                    <Row className='justify-content-center py-5'>
                        <Col lg="8" className='px-3'>
                            <p className='head mb-5'>Our Team</p>
                            <Row className='justify-content-center'>
                                <Col lg="3" md="3" sm="6" xs="6" className=''>
                                    <Card className="text-center cardstyle">
                                        <Card.Body >
                                            <div className='image-container'>
                                                <img variant="top" src={ashwin} className='proimg' />
                                            </div>
                                            <Card.Text className='text-white'>
                                                <Card.Title className='cardfooter'>Ashwin Shendre</Card.Title>
                                                Delivery Head
                                                <div>
                                                    <img src={linkedinimg} />
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className=''>
                                    <Card className="text-center cardstyle">
                                        <Card.Body >
                                            <div className='image-container'>
                                                <img variant="top" src={shrikant} className='proimg' />
                                            </div>
                                            <Card.Text className='text-white'>
                                                <Card.Title className='cardfooter'>Shrikant Ande</Card.Title>
                                                Mobile Development
                                                <div>
                                                    <img src={linkedinimg} />
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className=''>
                                    <Card className="text-center cardstyle">
                                        <Card.Body >
                                            <div className='image-container'>
                                                <img variant="top" src={kiran} className='proimg' />
                                            </div>
                                            <Card.Text className='text-white'>
                                                <Card.Title className='cardfooter'>Kirankumar Gadagi</Card.Title>
                                                Technology
                                                <div>
                                                    <img src={linkedinimg} />
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="3" md="3" sm="6" xs="6" className=''>
                                    <Card className="text-center cardstyle">
                                        <Card.Body >
                                            <div className='image-container'>
                                                <img variant="top" src={rohan} className='proimg' />
                                            </div>
                                            <Card.Text className='text-white'>
                                                <Card.Title className='cardfooter'>Rohan Gurav</Card.Title>
                                                UI/UX
                                                <div>
                                                    <img src={linkedinimg} />
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col lg="3" md="3" sm="6" xs="6" className=''>
                                    <Card className="text-center cardstyle">
                                        <Card.Body >
                                            <div className='image-container'>
                                                <img variant="top" src={rahul} className='proimg' />
                                            </div>
                                            <Card.Text className='text-white'>
                                                <Card.Title className='cardfooter'>Rahul Pola</Card.Title>
                                                Development Lead
                                                <div>
                                                    <img src={linkedinimg} />
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col lg="3" md="3" sm="6" xs="6" className=''>
                                    <Card className="text-center cardstyle">
                                        <Card.Body >
                                            <div className='image-container'>
                                                <img variant="top" src={sneha} className='proimg' />
                                            </div>
                                            <Card.Text className='text-white'>
                                                <Card.Title className='cardfooter'>Sneha Konkatti</Card.Title>
                                                Associate Lead - Development
                                                <div>
                                                    <img src={linkedinimg} />
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <div id="contact" ref={contactRef}>
                    <Row className='justify-content-center'>
                        <Col lg="8">
                            <p className='head mb-5 text-center'>Contact Us</p>
                            <Row>
                                    <Col lg="6" md="6" sm="12" xs="12" className='px-4'>
                                    <Row>
                                        <Col lg="7">
                                            <p className='head mb-5'>Get In Touch</p>
                                            <div className=''>
                                                <p className='txtstyle'>Feel free to reach out if you want to
                                                        collabarate with us,or simply have a call.</p>
                                            </div>
                                                <div className='mt-3'>
                                                <div className='d-flex'>
                                                    <img src={mail} className='me-2 icostyle' style={{ marginTop: "4px" }} />
                                                    <p className='txtstyle'>support@opethic.com</p>
                                                </div>

                                            </div>
                                                <div className='mt-3'>
                                                <div className='d-flex'>
                                                    <img src={phone} className='me-2 icostyle' style={{ marginTop: "4px" }} />
                                                    <p className='txtstyle'>9673355714 / 9922777711.</p>
                                                </div>
                                            </div>
                                                <div className='mt-3'>
                                                <div className='d-flex'>
                                                    <img src={location} className='me-2 icostyle' style={{ marginTop: "4px" }} />
                                                    <p className='txtstyle'>Bhadravathi Peth, Solapur, 413003.</p>
                                                </div>
                                            </div>
                                                <div className='d-flex mt-3'>
                                                <p className='text-white social me-2'>Follow Us</p>
                                                <img src={whatsapp} className='mx-3 icostyle' />
                                                <img src={linkedin} className='icostyle' />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                    <Col lg="6" md="6" sm="12" xs="12" className='px-4'>
                                        <p className='head mb-5 text-end'>Have Question ? Write a Message.</p>
                                        <Form onSubmit={handleSubmit} ref={formRef}>
                                        <Row>
                                            <Col>
                                                    <Form.Control placeholder="Full Name" className='fieldstyle'
                                                        name="fullname"
                                                        id="fulltname"
                                                        value={formData.fullname}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.fullname}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.fullname}
                                                    </Form.Control.Feedback>
                                            </Col>
                                            <Col>
                                                    <Form.Control placeholder="Email Address" className='fieldstyle'
                                                        name="email"
                                                        id="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.email}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col>
                                                    <Form.Control placeholder="Contact No" className='fieldstyle'
                                                        name="mobileno"
                                                        id="mobileno"
                                                        value={formData.mobileno}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobileno}
                                                        maxLength="10"
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.mobileno}
                                                    </Form.Control.Feedback>
                                            </Col>
                                            <Col>
                                                    <Form.Control placeholder="Subject" className='fieldstyle' />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Message"
                                                    // row="6"
                                                    style={{ height: "100px" }}
                                                        name="message"
                                                        id="message"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.message}
                                                        autoComplete="off"
                                                />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.message}
                                                    </Form.Control.Feedback>

                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col>
                                                    {/* <Button className='bannerbtn' type="button">Submit</Button> */}
                                                    <Button className="bannerbtn me-2 w-100" type="submit" disabled={loading}>
                                                        {loading ? (
                                                            <div className="spinner-container">
                                                                <Spinner as="span" animation="border" role="status" aria-hidden="true" className="custom-spinner" />
                                                                Submiting
                                                            </div>
                                                        ) : (

                                                            'Submit'
                                                        )}
                                                    </Button>
                                            </Col>
                                        </Row>
                                    </Form>


                                </Col>
                            </Row>
                                <p className='txtstyle mt-5 px-3'>
                                Copyright @ 2023 Opethic Software Services All rights reserved.
                            </p>
                        </Col>
                    </Row>
                    </div>
                </div>
            </div>
        </>
    )
}
